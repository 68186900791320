//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex';
import ClientListTable from '@/modules/client/components/client-list-table.vue';

export default {
  name: 'app-client-list-page',

  components: {
    [ClientListTable.name]: ClientListTable,
  },
  computed: {
    ...mapGetters({
      currentLanguageCode: 'layout/currentLanguageCode',
    }),
    isRTL() {
      // return localStorage.getItem('language') == 'ar'
      return this.currentLanguageCode == 'ar';
    },
  },
};
