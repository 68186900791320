import Permissions from '@/security/permissions';
import PermissionChecker from '@/modules/iam/permission-checker';

export class ClientPermissions {
  constructor(currentUser) {
    const permissionChecker = new PermissionChecker(
      currentUser,
    );

    this.read = permissionChecker.match(
      Permissions.values.clientRead,
    );
    this.import = permissionChecker.match(
      Permissions.values.clientImport,
    );
    this.clientAutocomplete = permissionChecker.match(
      Permissions.values.clientAutocomplete,
    );
    this.create = permissionChecker.match(
      Permissions.values.clientCreate,
    );
    this.edit = permissionChecker.match(
      Permissions.values.clientEdit,
    );
    this.destroy = permissionChecker.match(
      Permissions.values.clientDestroy,
    );
  }
}
